<template>
    <div class="w-100 my-4">
        <div class="col-12 mt-2">
            <h1 class="text-center h3">
                Historial de registro de inventarios
                {{ selectedMerchant.merchantType | merchantInfo("type") }}
                <strong class="text-primary">
                    {{ selectedMerchant.name }}
                </strong>
            </h1>
        </div>

        <div class="my-3 col-12">
            <time-filters v-model="query" />
            <div class="d-flex justify-content-center">
                <vs-button v-show="query" relief @click="listItems">
                    Consultar 🔎
                </vs-button>
            </div>
        </div>

        <div v-show="showTable" class="w-100 mt-3">
            <b-table
                :fields="$data.$fields"
                :items="itemsCurrent"
                :dark="activeDark"
                :per-page="itemsCurrent.length"
                responsive
                outlined
                no-border-collapse
                hover
                class="col-12"
            >
                <template #cell(id)="data">
                    <div class="d-flex align-items-center">
                        <router-link
                            :to="`/app/inventories/input-detail/${data.item.id}?merchantId=${selectedMerchant.merchantId}&merchantType=${selectedMerchant.merchantType}`"
                            class="text-center font-weight-normal btn btn-primary p-1"
                        >
                            <span>
                                {{ data.item.id }}
                            </span>
                        </router-link>
                    </div>
                </template>

                <template #cell(barcode)="data">
                    <router-link
                        :to="`/app/products/detail/${data.item.productId}?reference=${data.item.id}&active=references`"
                        class="text-center font-weight-normal btn btn-primary"
                    >
                        <small class="text-center">
                            {{ data.item.barcode || `ID ${data.item.id}` }}
                        </small>
                    </router-link>
                </template>

                <template #cell(sku)="data">
                    <router-link
                        :to="`/app/products/detail/${data.item.productId}`"
                        class="text-center font-weight-normal btn btn-primary"
                    >
                        <small class="text-center">
                            {{ data.item.sku || `ID ${data.item.productId}` }}
                        </small>
                    </router-link>
                </template>

                <template #cell(name)="data">
                    <button
                        icon
                        circle
                        class="btn-a"
                        @click="goToMainPage(data.item.slug)"
                    >
                        <small class="text-center">
                            {{ data.item.name }}
                        </small>
                    </button>
                </template>

                <template #cell(size)="data">
                    <small class="text-center text-capitalize">
                        {{ data.item.size }}
                    </small>
                </template>

                <template #cell(color)="data">
                    <small class="text-center text-capitalize">
                        {{ data.item.color }}
                    </small>
                </template>

                <template #cell(priceOffer)="data">
                    <div class="d-flex flex-column">
                        <small class="text-center">
                            {{ data.item.priceOffer | money }}
                        </small>
                        <small class="text-center text-through">
                            {{ data.item.price | money }}
                        </small>
                    </div>
                </template>

                <template #cell(costUnit)="data">
                    <small class="text-center">
                        {{ data.item.costUnit | money }}
                    </small>
                </template>

                <template #cell(provider)="data">
                    <small class="text-center">
                        {{ data.item.provider }}
                    </small>
                </template>

                <template #cell(createdAt)="data">
                    <small class="text-center font-weight-normal">
                        {{ data.item.createdAt | date }}
                    </small>
                </template>
            </b-table>
            <div v-if="!itemsCurrent.length" class="col-11 mx-auto mt-4">
                <strong>
                    Nuestro sistema NO encuentra resultados. Intenta modificar
                    los filtros de búsqueda.
                </strong>
            </div>
            <div v-else class="col-12 d-flex justify-content-center">
                <vs-button icon @click="downloadExcelComponent">
                    ⏬ Descargar excel
                </vs-button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

import { downloadExcel } from "@/common/lib/excel";

import TimeFilters from "@/components/utils/TimeFilters.vue";

export default {
    name: "HistoryInventories",
    components: { TimeFilters },
    data: () => ({
        showTable: false,
        itemsCurrent: [],
        $fields: [
            { key: "id", label: "ID", sortable: true },
            { key: "barcode", label: "Código de barras", sortable: true },
            { key: "sku", label: "Sku", sortable: true },
            { key: "name", label: "Nombre", sortable: true },
            { key: "costUnit", label: "Costo unidad", sortable: false },
            { key: "quantity", label: "Stock registradas", sortable: false },
            { key: "priceOffer", label: "Precio actual", sortable: false },
            { key: "createdAt", label: "Fecha de registro", sortable: true },
            { key: "quantity", label: "Stock registradas", sortable: false }
        ],
        loaderInstance: null,
        isLoading: false,
        query: {}
    }),
    computed: {
        ...mapState("control", ["activeDark"]),
        ...mapState("commons", ["selectedMerchant"]),
        ...mapGetters("control", ["backgroundColor"])
    },
    watch: {
        selectedMerchant() {
            if (this.showTable) {
                this.listItems();
            }
        }
    },
    async mounted() {
        const verifyPageScope = this.$ability.verifyPageScope.bind(this);
        if (!(await verifyPageScope("inventory:ListInput", "/app", true)))
            return;
        this.listItems();
    },
    methods: {
        ...mapActions("inventories", ["listInputsInventory"]),
        async listItems() {
            this.isLoading = true;
            this.loaderInstance = this.$vs.loading({ type: "square-rotate" });
            try {
                const inventory = await this.listInputsInventory({
                    query: this.query,
                    merchantId: this.selectedMerchant.merchantId,
                    merchantType: this.selectedMerchant.merchantType
                });
                this.itemsCurrent = inventory;
                this.showTable = true;
            } catch (error) {
                this.loaderInstance.close();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
            } finally {
                this.loaderInstance.close();
            }
        },
        downloadExcelComponent() {
            const filename = `inventories_${this.selectedMerchant.name.replaceAll(
                " ",
                "_"
            )}`;
            downloadExcel({ rows: this.itemsCurrent, filename });
        },
        goToMainPage(slug) {
            window.open(`${this.$marketplaceUrl}/${slug}`, "_blank").focus();
        }
    }
};
</script>
